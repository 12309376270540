import { Button, Nav, NavItem } from "reactstrap";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import user1 from "../assets/images/users/user4.jpg";
import probg from "../assets/images/bg/download.jpg";
// import { useAuth } from "../components/AuthContext";
import { useAuth } from "../components/AuthContext";

// const navigation = [
//   {
//     title: "Dashboard",
//     href: "/starter",
//     icon: "bi bi-speedometer2",
//   },
//   {
//     title: "Alert",
//     href: "/alerts",
//     icon: "bi bi-bell",
//   },
//   {
//     title: "Badges",
//     href: "/badges",
//     icon: "bi bi-patch-check",
//   },
//   {
//     title: "Buttons",
//     href: "/buttons",
//     icon: "bi bi-hdd-stack",
//   },
//   {
//     title: "Cards",
//     href: "/cards",
//     icon: "bi bi-card-text",
//   },
//   {
//     title: "Grid",
//     href: "/grid",
//     icon: "bi bi-columns",
//   },
//   {
//     title: "Table",
//     href: "/table",
//     icon: "bi bi-layout-split",
//   },
//   {
//     title: "Forms",
//     href: "/forms",
//     icon: "bi bi-textarea-resize",
//   },
//   {
//     title: "Breadcrumbs",
//     href: "/breadcrumbs",
//     icon: "bi bi-link",
//   },
//   {
//     title: "About",
//     href: "/about",
//     icon: "bi bi-people",
//   },
// ];

const adminNavigation = [
  {
    title: "Dashboard",
    href: "/painel_adm/starter",
    icon: "bi bi-speedometer2",
  },
  {
    title: "Admin Page",
    href: "/painel_adm/admin",
    icon: "bi bi-shield-lock",
  },
  {
    title: "Usuários",
    href: "/painel_adm/usuarios",
    icon: "bi bi-people",
  },
  {
    title: "Cidades",
    href: "/painel_adm/cidades",
    icon: "bi bi-layout-split",
  },
  {
    title: "Entregas",
    href: "/painel_adm/entregas",
    icon: "bi bi-truck",
  },
  // {
  //   title: "Dashboard",
  //   href: "/starter",
  //   icon: "bi bi-speedometer2",
  // },
  // {
  //   title: "Alert",
  //   href: "/alerts",
  //   icon: "bi bi-bell",
  // },
  // {
  //   title: "Badges",
  //   href: "/badges",
  //   icon: "bi bi-patch-check",
  // },
  // {
  //   title: "Buttons",
  //   href: "/buttons",
  //   icon: "bi bi-hdd-stack",
  // },
  // {
  //   title: "Cards",
  //   href: "/cards",
  //   icon: "bi bi-card-text",
  // },
  // {
  //   title: "Grid",
  //   href: "/grid",
  //   icon: "bi bi-columns",
  // },
  // {
  //   title: "Table",
  //   href: "/table",
  //   icon: "bi bi-layout-split",
  // },
  // {
  //   title: "Forms",
  //   href: "/forms",
  //   icon: "bi bi-textarea-resize",
  // },
  // {
  //   title: "Breadcrumbs",
  //   href: "/breadcrumbs",
  //   icon: "bi bi-link",
  // },
  // {
  //   title: "About",
  //   href: "/about",
  //   icon: "bi bi-people",
  // },
  // Adicione outros itens de menu específicos para admin
];
const admcidadeNavigation = [
  {
    title: "Início",
    href: "/painel/starter",
    icon: "bi bi-speedometer2",
  },
  {
    title: "Lojistas",
    href: "/painel/lojistas",
    icon: "bi bi-shop",
  },
  {
    title: "Entregas",
    href: "/painel/entregas",
    icon: "bi bi-truck",
  },
  // Adicione outros itens de menu específicos para lojista
];
const lojistaNavigation = [
  {
    title: "Início",
    href: "/starter",
    icon: "bi bi-speedometer2",
  },
  {
    title: "Lojista Page",
    href: "/lojista",
    icon: "bi bi-shop",
  },
  {
    title: "Entregas",
    href: "/entregas",
    icon: "bi bi-truck",
  },
  // Adicione outros itens de menu específicos para lojista
];

const motoboyNavigation = [
  {
    title: "Dashboard",
    href: "/starter",
    icon: "bi bi-speedometer2",
  },
  {
    title: "Motoboy Page",
    href: "/motoboy",
    icon: "bi bi-bicycle",
  },
  // Adicione outros itens de menu específicos para motoboy
];

const Sidebar = ({openModal}) => {
  const { userData } = useAuth();
  console.log("DATA " + userData);

  

  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };
  let location = useLocation();

  const getNavigation = () => {
    switch (userData?.role) {
      case "admin":
        return adminNavigation;
      case "admcidade":
        return admcidadeNavigation;
      case "lojista":
        return lojistaNavigation;
      case "motoboy":
        return motoboyNavigation;
      default:
        return [];
    }
  };

  const navigation = getNavigation();

  const handleOpenModal = () => {
    openModal(
      "Chamar entregador",
      "Este é o conteúdo do modal aberto pela Sidebar."
    );
  };

  return (
    <div>
      <div className="d-flex align-items-center"></div>
      <div
        className="profilebg"
        style={{ background: `url(${probg}) no-repeat` }}
      >
        <div className="p-3 d-flex">
          <img src={user1} alt="user" width="50" className="rounded-circle" />
          <Button
            color="white"
            className="ms-auto text-white d-lg-none"
            onClick={() => showMobilemenu()}
          >
            <i className="bi bi-x"></i>
          </Button>
        </div>
        {/* <div className="bg-dark text-white p-2 opacity-75">{userData.nome}</div> */}
        {userData && (
          <div className="bg-dark text-white p-2 opacity-75">
            {userData.nome}
          </div>
        )}
      </div>
      <div className="p-3 mt-2">
        <Nav vertical className="sidebarNav">
          {navigation.map((navi, index) => (
            <NavItem key={index} className="sidenav-bg">
              <Link
                to={navi.href}
                className={
                  location.pathname === navi.href
                    ? "active nav-link py-3"
                    : "nav-link text-secondary py-3"
                }
              >
                <i className={navi.icon}></i>
                <span className="ms-3 d-inline-block">{navi.title}</span>
              </Link>
            </NavItem>
          ))}

          {userData && userData.role === "lojista" && (
            <Button
              color="danger"
              tag="a"
              target="_blank"
              className="mt-3"
              onClick={handleOpenModal}
            >
              Chamar entregador
            </Button>
          )}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
