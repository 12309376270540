import React, { useState } from 'react';
import useApi from "../services/api";
import { useAuth } from "../components/AuthContext";

function AutocompleteClient({ onSelectClient }) {
    const api = useApi();

    const { userData } = useAuth();

    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const fetchSuggestions = async (input) => {
        if (input.length < 2) {
            setSuggestions([]);
            return;
        }

        // Faz uma chamada à API para buscar clientes com base no input
        const result = await api.buscaClientes(input, userData.id_cidade);
        console.log(result)
        // const response = await fetch(`/api/clientes?query=${input}`);
        // const data = await response.json();
        setSuggestions(result.list);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setQuery(value);
        fetchSuggestions(value);
    };

    const handleSelect = (client) => {
        setQuery(client.nome);
        setSuggestions([]);
        onSelectClient(client); // Passa o cliente selecionado para o componente pai
    };

    return (
        <div>
            <input 
                type="text"
                value={query}
                onChange={handleInputChange}
                placeholder="Digite o nome do cliente"
                className="form-control mb-3"
            />
            {suggestions.length > 0 && (
                <ul style={{ border: '1px solid #ccc', marginTop: '5px', maxHeight: '150px', overflowY: 'auto' }}>
                    {suggestions.map((client) => (
                        <li 
                            key={client.id} 
                            onClick={() => handleSelect(client)}
                            style={{ cursor: 'pointer', padding: '5px', color: '#007BFF' }}
                        >
                            {client.nome_cliente} - {client.telefone_cliente}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default AutocompleteClient;
