import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Table,
    Button,
  } from "reactstrap";
  import user1 from "../../assets/images/users/user1.jpg";
  import user2 from "../../assets/images/users/user2.jpg";
  import user3 from "../../assets/images/users/user3.jpg";
  import user4 from "../../assets/images/users/user4.jpg";
  import user5 from "../../assets/images/users/user5.jpg";
  import { useNavigate } from 'react-router-dom';
  
  
  // const tableData = [
  //   {
  //     avatar: user1,
  //     name: "Hanna Gover",
  //     email: "hgover@gmail.com",
  //     project: "Flexy React",
  //     status: "pending",
  //     weeks: "35",
  //     budget: "95K",
  //   },
  //   {
  //     avatar: user2,
  //     name: "Hanna Gover",
  //     email: "hgover@gmail.com",
  //     project: "Lading pro React",
  //     status: "done",
  //     weeks: "35",
  //     budget: "95K",
  //   },
  //   {
  //     avatar: user3,
  //     name: "Hanna Gover",
  //     email: "hgover@gmail.com",
  //     project: "Elite React",
  //     status: "holt",
  //     weeks: "35",
  //     budget: "95K",
  //   },
  //   {
  //     avatar: user4,
  //     name: "Hanna Gover",
  //     email: "hgover@gmail.com",
  //     project: "Flexy React",
  //     status: "pending",
  //     weeks: "35",
  //     budget: "95K",
  //   },
  //   {
  //     avatar: user5,
  //     name: "Hanna Gover",
  //     email: "hgover@gmail.com",
  //     project: "Ample React",
  //     status: "done",
  //     weeks: "35",
  //     budget: "95K",
  //   },
  // ];
  
  const ProjectTablesPainel = ({ dados = [] }) => {
  
    const navigate = useNavigate();
  
    const handleEditClick = (id_user, nome, email) => {
      navigate('/painel/editar', { state: { id_user, nome, email } });
    };
    console.log(dados);
    return (
      <div>
        <Card>
          <CardBody>
            <CardTitle tag="h5">Usuários</CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6">
              Usuários cadastrados no sistema
            </CardSubtitle>
  
            <Table className="no-wrap mt-3 align-middle" responsive borderless>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
  
                  <th>Tipo</th>
                  <th style={{width:170}}>#</th>
                </tr>
              </thead>
              <tbody>
                {dados.map((tdata, index) => (
                  <tr key={index} className="border-top">
                    <td>
                      {tdata.nome}
                      {/* <div className="d-flex align-items-center p-2">
                        <img
                          src={tdata.avatar}
                          className="rounded-circle"
                          alt="avatar"
                          width="45"
                          height="45"
                        />
                        <div className="ms-3">
                          <h6 className="mb-0">{tdata.nome}</h6>
                          <span className="text-muted">{tdata.email}</span>
                        </div>
                      </div> */}
                    </td>
                    <td>{tdata.email}</td>
                    <td>
                      {tdata.role}
                      {/* {tdata.status === "pending" ? (
                        <span className="p-2 bg-danger rounded-circle d-inline-block ms-3"></span>
                      ) : tdata.status === "holt" ? (
                        <span className="p-2 bg-warning rounded-circle d-inline-block ms-3"></span>
                      ) : (
                        <span className="p-2 bg-success rounded-circle d-inline-block ms-3"></span>
                      )} */}
                    </td>
                    <td>
                      <div className="button-group">
                        <Button className="btn" color="primary" onClick={() => handleEditClick(tdata.id, tdata.nome, tdata.email)}>
                          Editar
                        </Button>
                        <Button className="btn" color="warning">
                          Teste
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    );
  };
  
  export default ProjectTablesPainel;
  