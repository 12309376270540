import { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import useApi from "../../../services/api";
import CustomAlert from "../../../components/CustomAlert";

const Editar = () => {
  const api = useApi();

  const location = useLocation();
  const {
    id_user,
    nome: initialNome,
    email: initialEmail,
  } = location.state || {};

  const [nome, setNome] = useState(initialNome || "");
  const [email, setEmail] = useState(initialEmail || "");
  const [selectValue, setSelectValue] = useState("1");
  const [status, setStatus] = useState(false);
  const [premium, setPremium] = useState(false);
  const [entregador, setEntregador] = useState(false);
  const [perguntarRefri, setPerguntarRefri] = useState(false);
  const [campoResumo, setCampoResumo] = useState(false);
  const [mostrarRelatorios, setMostrarRelatorios] = useState(false);

  const [alert, setAlert] = useState({ isOpen: false, title: "", message: "" });

  const toggleAlert = () => setAlert({ ...alert, isOpen: !alert.isOpen });

  const getUserData = async (e) => {
    const result = await api.getUserData(id_user);
    if (result.error === "") {
        setStatus(result.data.status)
        setPremium(result.data.premium)
        setEntregador(result.data.entregador)
        setPerguntarRefri(result.data.perguntarRefri)
        setCampoResumo(result.data.campoResumo)
        setMostrarRelatorios(result.data.mostrarRelatorios)
    } else {
      setAlert({ isOpen: true, title: "Erro", message: result.error });
    }
  };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddUser = async (e) => {
    e.preventDefault();

    const newUserData = {
      id_user,
      nome,
      email,
      selectValue,
      status,
      premium,
      entregador,
      perguntarRefri,
      campoResumo,
      mostrarRelatorios,
    };
    const result = await api.updateUser(id_user, newUserData);
    if (result.error === "") {
      // getList();
      // toggleModal();
      // setNome("");
      // setEmail("");
      // setPassword("");
      // setRole("");
      setAlert({
        isOpen: true,
        title: "Sucesso",
        message: "Usuário atualizado com sucesso!",
      });
    } else {
      setAlert({ isOpen: true, title: "Erro", message: result.error });
    }
  };

  return (
    <Row>
      <Col>
        {/* --------------------------------------------------------------------------------*/}
        {/* Card-1*/}
        {/* --------------------------------------------------------------------------------*/}
        <Card>
          <CardTitle tag="h6" className="border-bottom p-3 mb-0">
            <i className="bi bi-bell me-2"> </i>
            Editar usuário
          </CardTitle>
          <CardBody>
            <Form onSubmit={handleAddUser}>
              <Row>
                <Col sm="6">
                  <FormGroup>
                    <Label for="nome">Nome</Label>
                    <Input
                      id="nome"
                      name="nome"
                      placeholder="Nome"
                      type="text"
                      value={nome}
                      onChange={(e) => setNome(e.target.value)} // Atualiza o estado nome
                    />
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)} // Atualiza o estado email
                    />
                    <Input
                      id="id_user"
                      name="id_user"
                      type="hidden"
                      readOnly
                      value={id_user}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Label for="exampleSelect">Selecionar</Label>
                <Input
                  id="exampleSelect"
                  name="select"
                  type="select"
                  value={selectValue}
                  onChange={(e) => setSelectValue(e.target.value)} // Atualiza o estado selectValue
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Input>
              </FormGroup>
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="status"
                  checked={status}
                  onChange={(e) => setStatus(e.target.checked)} // Atualiza o estado status
                />{" "}
                <Label check>Ativo</Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="premium"
                  checked={premium}
                  onChange={(e) => setPremium(e.target.checked)} // Atualiza o estado premium
                />{" "}
                <Label check>Premium</Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="entregador"
                  checked={entregador}
                  onChange={(e) => setEntregador(e.target.checked)} // Atualiza o estado entregador
                />{" "}
                <Label check>Entregador</Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="perguntar_refri"
                  checked={perguntarRefri}
                  onChange={(e) => setPerguntarRefri(e.target.checked)} // Atualiza o estado perguntarRefri
                />{" "}
                <Label check>Perguntar sobre refrigerante</Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="campo_resumo"
                  checked={campoResumo}
                  onChange={(e) => setCampoResumo(e.target.checked)} // Atualiza o estado campoResumo
                />{" "}
                <Label check>Oferecer campo de colar resumo do pedido</Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="mostrar_relatorios"
                  checked={mostrarRelatorios}
                  onChange={(e) => setMostrarRelatorios(e.target.checked)} // Atualiza o estado mostrarRelatorios
                />{" "}
                <Label check>Mostrar relatório</Label>
              </FormGroup>
              <Button type="submit" className="mt-3">
                Salvar
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Col>
      <CustomAlert
        isOpen={alert.isOpen}
        toggle={toggleAlert}
        title={alert.title}
        message={alert.message}
      />
    </Row>
  );
};

export default Editar;
