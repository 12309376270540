import { lazy } from "react";
import { Navigate } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute.js";
import RoleProtectedRoute from "../components/RoleProtectedRoute.js";

/**** Layouts *****/
import FullLayout from "../layouts/FullLayout";

/***** Pages ****/
import Starter from "../views/Starter";
import About from "../views/About";
import Alerts from "../views/ui/Alerts";
import Badges from "../views/ui/Badges";
import Buttons from "../views/ui/Buttons";
import Cards from "../views/ui/Cards";
import Grid from "../views/ui/Grid";
import Tables from "../views/ui/Tables";
import Forms from "../views/ui/Forms";
import Breadcrumbs from "../views/ui/Breadcrumbs";
import Login from "../views/Login";
import Logout from "../views/Logout";

import Usuarios from "../views/Admin/Usuarios/Index";
import EditarUser from "../views/Admin/EditarUser/Index";
import Cidades from "../views/Admin/Cidades/Index";
import Entregas from "../views/Admin/Entregas/Index";

import Lojistas from "../views/Painel/Lojistas/Index";
import EditarUserPainel from "../views/Admin/EditarUser/Index";
import StarterPainel from "../views/Painel/Starter";

// TUDO LOJISTA
import EntregasLojista from "../views/Lojista/Entregas/Index";

/*****Routes******/

const ThemeRoutes = [
  { path: "/login", element: <Login /> }, // Adicionar a rota de Login
  { path: "/logout", element: <Logout /> }, // Adicionar a rota de Login
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/starter" /> },
      { path: "/painel_adm/starter",  element:<RoleProtectedRoute component={Starter} requiredRole="admin" /> },
      // { path: "/painel_adm/starter",  element: <PrivateRoute component={Starter} /> },
      { path: "/painel_adm/usuarios",  element: <RoleProtectedRoute component={Usuarios} requiredRole="admin"/> },
      { path: "/painel_adm/editar",  element: <RoleProtectedRoute component={EditarUser} requiredRole="admin"/> },
      { path: "/painel_adm/cidades",  element: <RoleProtectedRoute component={Cidades} requiredRole="admin" /> },
      // ROTAS ADM CIDADE
      { path: "/painel/starter",  element: <PrivateRoute component={StarterPainel} /> },
      { path: "/painel/lojistas",  element: <PrivateRoute component={Lojistas} /> },
      { path: "/painel/editar",  element: <PrivateRoute component={EditarUserPainel} requiredRole=""/> },
      { path: "/painel/entregas",  element: <PrivateRoute component={EntregasLojista} /> },
      { path: "/starter",  element: <PrivateRoute component={Starter} /> },
      { path: "/about",  element: <PrivateRoute component={About} /> },
      { path: "/alerts",  element: <PrivateRoute component={Alerts} /> },
      { path: "/badges",  element: <PrivateRoute component={Badges} /> },
      { path: "/buttons",  element: <PrivateRoute component={Buttons} /> },
      { path: "/cards",  element: <PrivateRoute component={Cards} /> },
      { path: "/grid",  element: <PrivateRoute component={Grid} /> },
      { path: "/table",  element: <PrivateRoute component={Tables} /> },
      { path: "/forms",  element: <PrivateRoute component={Forms} /> },
      { path: "/breadcrumbs",  element: <PrivateRoute component={Breadcrumbs} /> },
      
      // ROTAS LOJISTA
      { path: "/entregas",  element: <PrivateRoute component={EntregasLojista} /> },
    ],
  },
];

export default ThemeRoutes;
