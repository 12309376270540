import { useState, useEffect } from "react";
import ProjectTables from "../../../components/dashboard/ProjectTable";
import {
  Row,
  Col,
  Table,
  Card,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import useApi from "../../../services/api";
import CustomAlert from "../../../components/CustomAlert";

const Usuarios = () => {
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [profile, setProfile] = useState([]);

  const [modal, setModal] = useState(false); // Estado para controlar a visibilidade do modal
  const [alert, setAlert] = useState({ isOpen: false, title: "", message: "" });


  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");

  const toggleModal = () => setModal(!modal); // Função para alternar a visibilidade do modal

  const toggleAlert = () => setAlert({ ...alert, isOpen: !alert.isOpen });

  const getList = async () => {
    setLoading(true);
    const result = await api.getUsers();
    // const profiles = await api.getProfiles();
    setLoading(false);
    console.log(result);
    if (result.error === "") {
      setList(result.list);
      // setProfile(profiles.list);
    } else {
      setLoading(false);
      alert(result.error);
    }
  };

  const handleAddUser = async () => {
    const newCidade = { nome, email, password, role };
    const result = await api.addUser(newCidade);
    if (result.error === "") {
      getList();
      toggleModal();

      setNome("");
      setEmail("");
      setPassword("");
      setRole("");

      setAlert({
        isOpen: true,
        title: "Sucesso",
        message: "Usuário adicionado com sucesso!",
      });
    } else {
      setAlert({ isOpen: true, title: "Erro", message: result.error });
    }
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      {/* --------------------------------------------------------------------------------*/}
      {/* table-1*/}
      {/* --------------------------------------------------------------------------------*/}
      <Col lg="12 text-end mb-3">
        <Button className="btn" color="primary" onClick={toggleModal}>
          Adicionar novo
        </Button>
      </Col>
      <Col lg="12">
        <ProjectTables dados={list} />
      </Col>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Adicionar novo usuário</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="nome">Nome</Label>
              <Input
                type="text"
                id="nome"
                name="nome"
                placeholder="Nome do usuário"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Senha</Label>
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Digite a senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="role">Tipo</Label>
              <Input
                type="select"
                id="role"
                name="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="">Selecione um tipo</option>
                <option value="admin">Admin</option>
                <option value="lojista">Lojista</option>
                <option value="motoboy">Motoboy</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddUser}>
            Salvar
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <CustomAlert
        isOpen={alert.isOpen}
        toggle={toggleAlert}
        title={alert.title}
        message={alert.message}
      />
      {/* --------------------------------------------------------------------------------*/}
      {/* table-2*/}
      {/* --------------------------------------------------------------------------------*/}
      {/* <Col lg="12">
        <Card>
          <CardTitle tag="h6" className="border-bottom p-3 mb-0">
            <i className="bi bi-card-text me-2"> </i>
            Usuários
          </CardTitle>
          <CardBody className="">
            <p>{loading ? "Carregando" : "Entrar"}</p>
            <Table bordered>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Tipo</th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <tr>
                    <th scope="row">1</th>
                    <td>{item.nome}</td>
                    <td>{item.email}</td>
                    <td>{item.role}</td>
                  </tr>
                  // <NavItem key={index} className="sidenav-bg">
                  //   <Link
                  //     to={navi.href}
                  //     className={
                  //       location.pathname === navi.href
                  //         ? "active nav-link py-3"
                  //         : "nav-link text-secondary py-3"
                  //     }
                  //   >
                  //     <i className={navi.icon}></i>
                  //     <span className="ms-3 d-inline-block">{navi.title}</span>
                  //   </Link>
                  // </NavItem>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col> */}
      {/* --------------------------------------------------------------------------------*/}
      {/* table-3*/}
      {/* --------------------------------------------------------------------------------*/}
      {/* <Col lg="12">
        <Card>
          <CardTitle tag="h6" className="border-bottom p-3 mb-0">
            <i className="bi bi-card-text me-2"> </i>
            Table with Striped
          </CardTitle>
          <CardBody className="">
            <Table bordered striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Username</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Larry</td>
                  <td>the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col> */}
      {/* --------------------------------------------------------------------------------*/}
      {/* table-3*/}
      {/* --------------------------------------------------------------------------------*/}
      {/* <Col lg="12">
        <Card>
          <CardTitle tag="h6" className="border-bottom p-3 mb-0">
            <i className="bi bi-card-text me-2"> </i>
            Table with Hover
          </CardTitle>
          <CardBody className="">
            <Table bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Username</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Larry</td>
                  <td>the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col> */}
    </Row>
  );
};

export default Usuarios;
