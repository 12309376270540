import React, { Suspense } from 'react';
import { useRoutes } from "react-router-dom";
import ThemeRoutes from "./routes/Router";
import { AuthProvider } from "./components/AuthContext";
import Loader from "./layouts/loader/Loader";

const App = () => {
  const routing = useRoutes(ThemeRoutes);

  return (
    <AuthProvider>
        <div className="dark">{routing}</div>
    </AuthProvider>
  );
};

export default App;
