import React, { useState, useEffect } from "react";
import { useAuth } from "../components/AuthContext";
import { useNavigate } from "react-router-dom";
import { Alert } from "reactstrap";
// import api from "../services/api";
import useApi from "../services/api";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// import { getAnalytics } from "firebase/analytics";
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import "firebase/auth";

import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("chock_321@outlook.com");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  // const [userDados, setUserData] = useState("");
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, userData, login } = useAuth();
  const navigate = useNavigate();

  const api = useApi();

  // Configuração do Firebase (substitua pelos seus valores)
  const firebaseConfig = {
    apiKey: "AIzaSyBO1VXxQd4tYWVjm0vOe03cW6VEmKRLgQ0",
    authDomain: "svrvainabag.firebaseapp.com",
    projectId: "svrvainabag",
    storageBucket: "svrvainabag.appspot.com",
    messagingSenderId: "1098454932268",
    appId: "1:1098454932268:web:e86f199695dcbb779bea89",
    measurementId: "G-75RC45V2N9",
  };
  //   const app = initializeApp(firebaseConfig);
  //   const analytics = getAnalytics(app);

  // initializeApp(firebaseConfig);
  firebase.initializeApp(firebaseConfig);
  //   const auth = firebase.auth();
  //   const auth = getAuth();

  const authenticateWithCustomToken = async (customToken) => {
    try {
      await firebase.auth().signInWithCustomToken(customToken);
      const idToken = await firebase.auth().currentUser.getIdToken();
      console.log("TOKEEN FIRE " + idToken);
      localStorage.setItem("token", idToken);

      // navigate("/starter");
    } catch (error) {
      console.error("Authentication error:", error);
    }
    // try {
    //   await app.auth().signInWithCustomToken(customToken);
    //   const idToken = await app.auth().currentUser.getIdToken();
    //   console.log("ID TOKEN " + idToken);
    //   localStorage.setItem("token", idToken);
    // } catch (error) {
    //   console.error("Authentication error:", error);
    // }
  };
  
  const handleLogin = async (e) => {
    e.preventDefault(); // Evita que o formulário seja enviado normalmente (evita atualização da página)

    if (email && password) {
      setLoading(true);
      const result = await api.login(email, password);
      console.log(result);
      setLoading(false);

      if (result.error === "") {
        // setUserData(result.user.userData);
        var userData = result.user.userData;

        login(result.token, result.user.userData);
        authenticateWithCustomToken(result.token);
        // localStorage.setItem("token", result.token);
        localStorage.setItem("userId", result.user.uid);
        // localStorage.setItem("userData", JSON.stringify(result.user.userData));
        console.log("LOGIN ROLE " + userData.role);
        if (userData.role === "admin") {
          navigate("/painel_adm/starter");
        } else if (userData.role === "loja") {
          navigate("/painel/starter");
        } else {
          navigate("/starter");
        }
        // 
      } else {
        setError(result.error);
        setSuccess("");
      }
    } else {
      setError("Digite seus dados");
      setSuccess("");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      
      if (userData.role === "admin") {
        navigate("/painel_adm/starter");
      } else if (userData.role === "admcidade") {
        navigate("/painel/starter");
      } else {
        navigate("/starter");

      }
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className={"mainContainer"}>
      <div className={"titleContainer"}>
        <div>Login</div>
      </div>
      <br />
      {error !== "" && <Alert color="danger">{error}</Alert>}
      {success !== "" && <Alert color="success">{success}</Alert>}
      <div className={"inputContainer"}>
        <input
          value={email}
          placeholder="Insira seu e-mail"
          onChange={(ev) => setEmail(ev.target.value)}
          className={"inputBox"}
        />
        <label className="errorLabel">{emailError}</label>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          value={password}
          placeholder="Sua senha"
          onChange={(ev) => setPassword(ev.target.value)}
          className={"inputBox"}
        />
        <label className="errorLabel">{passwordError}</label>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          className={"inputButton"}
          type="button"
          onClick={handleLogin}
          value={loading ? "Carregando" : "Entrar"}
        />
      </div>
    </div>
  );
};

export default Login;
