import { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  Input,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Card,
  CardTitle,
} from "reactstrap";
import { useAuth } from "../components/AuthContext";
import useApi from "../services/api";
import CustomAlert from "../components/CustomAlert";
import firebase from "firebase/compat/app";
import AutocompleteClient from "../components/Autocomplete";

const FullLayout = () => {
  const api = useApi();

  const { userData } = useAuth();

  const uid = localStorage.getItem("userId");
  console.log(uid);

  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", body: "" });

  const [alert, setAlert] = useState({ isOpen: false, title: "", message: "" });

  const toggleAlert = () => setAlert({ ...alert, isOpen: !alert.isOpen });

  const [metodo, setMetodo] = useState("");
  const [nome_cliente, setNomeCliente] = useState("");
  const [telefone_cliente, setTelCliente] = useState("");
  const [bairro_setor, setBairro] = useState("");
  const [endereco, setEndereco] = useState("");
  const [troco_cliente, setTroco] = useState("");
  const [total_receber, setTotalReceber] = useState("");
  const [observacoes, setObs] = useState("");

  const [activeTab, setActiveTab] = useState("1");

  const [selectedClient, setSelectedClient] = useState(null);

  const handleClientSelect = (client) => {
    setSelectedClient(client.id);
    setNomeCliente(client.nome_cliente);
    setTelCliente(client.telefone_cliente);
    setBairro(client.bairro_setor);
    setEndereco(client.endereco);
  };

  const toggle = (tab, metodo) => {
    setMetodo(metodo);
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleModal = () => setModal(!modal);

  const openModal = (title, body) => {
    setModalContent({ title, body });
    setModal(true);
  };

  const handleAddEntrega = async ({ tipoentrega }) => {
    const created = firebase.firestore.Timestamp.fromDate(new Date()).toDate();
    console.log(created);

    const newCidade = {
      id_cidade: userData.id_cidade,
      id_user: uid,
      metodo,
      nome_cliente,
      telefone_cliente,
      bairro_setor,
      endereco,
      troco_cliente,
      total_receber,
      observacoes,
      tipoentrega: tipoentrega,
      status: "aguardando",
      status_motoboy: "",
      created: created,
      id_cliente: selectedClient ?? "",
    };
    const result = await api.addEntrega(newCidade);
    if (result.error === "") {
      // getList();
      toggleModal();

      setMetodo("");
      setNomeCliente("");
      setTelCliente("");
      setBairro("");
      setEndereco("");
      setTroco("");
      setTotalReceber("");
      setObs("");

      setAlert({
        isOpen: true,
        title: "Sucesso",
        message: "Entrega adicionada com sucesso!",
      });
    } else {
      setAlert({ isOpen: true, title: "Erro", message: result.error });
    }
  };

  return (
    <main>
      {/********header**********/}
      <Header />
      <div className="pageWrapper d-lg-flex">
        {/********Sidebar**********/}
        <aside className="sidebarArea shadow" id="sidebarArea">
          <Sidebar openModal={openModal} />
        </aside>
        {/********Content Area**********/}
        <div className="contentArea">
          {/********Middle Content**********/}
          <Container className="p-4" fluid>
            <Outlet />
          </Container>
        </div>
      </div>

      {/********Global Modal**********/}
      <Modal size="xl" isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{modalContent.title}</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col lg="6 text-start mb-3">
                <AutocompleteClient onSelectClient={handleClientSelect} />

                {/* {selectedClient && (
                  <div style={{ marginTop: "20px" }}>
                    <div>
                      <label>Telefone:</label>
                      <p>{selectedClient.telefone}</p>
                    </div>
                    <div>
                      <label>Endereço:</label>
                      <p>{selectedClient.endereco}</p>
                    </div>
                  </div>
                )} */}
                {/* <FormGroup>
                  <Label for="nome">Quem vai receber</Label>
                  <Input
                    type="text"
                    id="nome_cliente"
                    name="nome_cliente"
                    placeholder="Nome do cliente"
                    value={nome_cliente}
                    onChange={(e) => setNomeCliente(e.target.value)}
                  />
                </FormGroup> */}
                <FormGroup>
                  <Label for="email">Telefone do cliente</Label>
                  <Input
                    type="text"
                    id="telefone_cliente"
                    name="telefone_cliente"
                    placeholder=""
                    value={telefone_cliente}
                    onChange={(e) => setTelCliente(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Bairro ou setor</Label>
                  <Input
                    type="text"
                    id="bairro_setor"
                    name="bairro_setor"
                    placeholder=""
                    value={bairro_setor}
                    onChange={(e) => setBairro(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Endereço</Label>
                  <Input
                    type="text"
                    id="endereco"
                    name="endereco"
                    placeholder=""
                    value={endereco}
                    onChange={(e) => setEndereco(e.target.value)}
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label for="role">Tipo</Label>
                  <Input
                    type="select"
                    id="role"
                    name="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="">Selecione um tipo</option>
                    <option value="admin">Admin</option>
                    <option value="lojista">Lojista</option>
                    <option value="motoboy">Motoboy</option>
                  </Input>
                </FormGroup> */}
              </Col>
              <Col lg="6 text-start mb-3">
                <Nav fill justified pills tabs className="">
                  <NavItem>
                    <NavLink
                      className={{ active: activeTab === "1" }}
                      onClick={() => {
                        toggle("1", "cartao");
                      }}
                    >
                      CARTÃO
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: activeTab === "2" }}
                      onClick={() => {
                        toggle("2", "dinheiro");
                      }}
                    >
                      DINHEIRO
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: activeTab === "3" }}
                      onClick={() => {
                        toggle("3", "pix");
                      }}
                    >
                      PIX
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={{ active: activeTab === "4" }}
                      onClick={() => {
                        toggle("4", "pago");
                      }}
                    >
                      PAGO
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  style={{ padding: "30px 0px" }}
                  activeTab={activeTab}
                >
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">{/* <h4>Sem entregas.</h4> */}</Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label for="email">Troco para o cliente</Label>
                          <Input
                            type="text"
                            id="troco_cliente"
                            name="troco_cliente"
                            placeholder=""
                            // initialValue="0"
                            value={troco_cliente}
                            onChange={(e) => setTroco(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">{/* <h4>Tab 3 Contents</h4> */}</Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    <Row>
                      <Col sm="12">{/* <h4>Tab 4 Contents</h4> */}</Col>
                    </Row>
                  </TabPane>
                </TabContent>
                <FormGroup>
                  <Label for="email">Total a receber</Label>
                  <Input
                    type="text"
                    id="total_receber"
                    name="total_receber"
                    placeholder=""
                    value={total_receber}
                    onChange={(e) => setTotalReceber(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Observações</Label>
                  <Input
                    type="textarea"
                    id="observacoes"
                    name="observacoes"
                    rows="3"
                    value={observacoes}
                    onChange={(e) => setObs(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col lg="6 text-start mb-3">
                <Button
                  color="danger"
                  tag="a"
                  className="mt-3 w-100"
                  onClick={() =>
                    handleAddEntrega({ tipoentrega: "chamaragora" })
                  }
                >
                  CHAMAR AGORA
                </Button>
              </Col>
              <Col lg="6 text-start mb-3">
                <Button
                  color="primary"
                  tag="a"
                  className="mt-3 w-100"
                  onClick={() => handleAddEntrega({ tipoentrega: "empreparo" })}
                >
                  EM PREPARO
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
      <CustomAlert
        isOpen={alert.isOpen}
        toggle={toggleAlert}
        title={alert.title}
        message={alert.message}
      />
    </main>
  );
};

export default FullLayout;
