import React from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

const CustomAlert = ({ isOpen, toggle, title, message }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <p>{message}</p>
        <Button color="primary" onClick={toggle}>OK</Button>
      </ModalBody>
    </Modal>
  );
};

export default CustomAlert;
