import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const RoleProtectedRoute = ({ component: Component, requiredRole, ...rest }) => {
  const { isAuthenticated, userData, loading } = useAuth();
  console.log("AUTENTEICADO "+isAuthenticated)
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated || !userData || userData.role !== requiredRole) {
    return <Navigate to="/login" />;
  }

  return <Component {...rest} />;
};

export default RoleProtectedRoute;
