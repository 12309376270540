import { useState, useEffect } from "react";
import ProjectTables from "../../../components/dashboard/ProjectTable";
import {
  Row,
  Col,
  Table,
  Card,
  CardTitle,
  CardBody,
  Button,
  CardSubtitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  FormText,
} from "reactstrap";
import useApi from "../../../services/api";
import CustomAlert from "../../../components/CustomAlert";

const Cidades = () => {
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [profile, setProfile] = useState([]);

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [nome_cidade, setNomeCidade] = useState("");
  const [password, setPassword] = useState("");

  const [modal, setModal] = useState(false); // Estado para controlar a visibilidade do modal
  const [alert, setAlert] = useState({ isOpen: false, title: "", message: "" });

  const toggleModal = () => setModal(!modal); // Função para alternar a visibilidade do modal

  const getCidades = async () => {
    setLoading(true);
    const result = await api.getCidades();
    // const profiles = await api.getProfiles();
    setLoading(false);
    console.log(result);
    if (result.error === "") {
      setList(result.list);
      // setProfile(profiles.list);
    } else {
      setLoading(false);
      alert(result.error);
    }
  };

  const handleAddCidade = async () => {
    const newCidade = { nome, email, role, nome_cidade, password };
    const result = await api.addCidade(newCidade);
    if (result.error === "") {
      getCidades();
      toggleModal();

      setNome("");
      setEmail("");
      setRole("");
      setNomeCidade("");
      setPassword("");

      setAlert({
        isOpen: true,
        title: "Sucesso",
        message: "Cidade adicionada com sucesso!",
      });
    } else {
      setAlert({ isOpen: true, title: "Erro", message: result.error });
    }
  };

  const toggleAlert = () => setAlert({ ...alert, isOpen: !alert.isOpen });

  useEffect(() => {
    getCidades();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Row>
        {/* --------------------------------------------------------------------------------*/}
        {/* table-1*/}
        {/* --------------------------------------------------------------------------------*/}
        <Col lg="12 text-end mb-3">
          <Button className="btn" color="primary" onClick={toggleModal}>
            Adicionar novo
          </Button>
        </Col>
        <Col lg="12">
          <Card>
            <CardBody>
              <CardTitle tag="h5">Cidades</CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                Cidades cadastradas no sistema
              </CardSubtitle>

              <Table
                className="no-wrap mt-3 align-middle"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Criada em</th>

                    <th style={{ width: 170 }}>#</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((tdata, index) => (
                    <tr key={index} className="border-top">
                      <td>
                        {tdata.nome_cidade}
                        {/* <div className="d-flex align-items-center p-2">
                      <img
                        src={tdata.avatar}
                        className="rounded-circle"
                        alt="avatar"
                        width="45"
                        height="45"
                      />
                      <div className="ms-3">
                        <h6 className="mb-0">{tdata.nome}</h6>
                        <span className="text-muted">{tdata.email}</span>
                      </div>
                    </div> */}
                      </td>
                      <td>
                        {tdata.created_at && tdata.created_at._seconds
                          ? new Intl.DateTimeFormat("pt-BR", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }).format(tdata.created_at._seconds * 1000) // Multiplica por 1000 para converter de segundos para milissegundos
                          : null}
                      </td>
                      <td>
                        <div className="button-group">
                          <Button className="btn" color="primary">
                            Editar
                          </Button>
                          <Button className="btn" color="warning">
                            Teste
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Adicionar Nova Cidade</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="nome">Nome da cidade</Label>
              <Input
                type="text"
                id="nome_cidade"
                name="nome_cidade"
                placeholder="Nome da cidade"
                value={nome_cidade}
                onChange={(e) => setNomeCidade(e.target.value)}
              />
            </FormGroup>
            <FormText>Dados do admin</FormText>
            <FormGroup>
              <Label for="nome">Nome</Label>
              <Input
                type="text"
                id="nome"
                name="nome"
                placeholder="Nome do usuario"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Senha</Label>
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="role">Tipo</Label>
              <Input
                type="select"
                id="role"
                name="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="">Selecione um tipo</option>
                <option value="admincidade">Admin</option>
                {/* <option value="lojista">Lojista</option>
                <option value="motoboy">Motoboy</option> */}
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddCidade}>
            Salvar
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <CustomAlert
        isOpen={alert.isOpen}
        toggle={toggleAlert}
        title={alert.title}
        message={alert.message}
      />
    </div>
  );
};

export default Cidades;
