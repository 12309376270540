import { useState, useEffect } from "react";
import ProjectTables from "../../../components/dashboard/ProjectTable";
import {
  Row,
  Col,
  Table,
  Card,
  CardTitle,
  CardBody,
  Button,
  CardSubtitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
} from "reactstrap";
import useApi from "../../../services/api";
import CustomAlert from "../../../components/CustomAlert";
import { useAuth } from "../../../components/AuthContext";
import EntregaTable from "../../../components/EntregaTable";

const Entregas = () => {
  const api = useApi();
  const { userData } = useAuth();

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [profile, setProfile] = useState([]);

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const [modal, setModal] = useState(false); // Estado para controlar a visibilidade do modal
  const [alert, setAlert] = useState({ isOpen: false, title: "", message: "" });

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      // Chama o getEntregas com o status correspondente ao tabId
      const status =
        tab === "1" ? "aguardando" : tab === "2" ? "aceita" : "entregue";
      getEntregas(status);
    }
  };

  const toggleModal = () => setModal(!modal); // Função para alternar a visibilidade do modal

  const getEntregas = async (status) => {
    setLoading(true);
    const result = await api.getEntregasCidade(userData.id_cidade, status);
    // const profiles = await api.getProfiles();
    setLoading(false);
    console.log(result);
    if (result.error === "") {
      setList(result.list);
      // setProfile(profiles.list);
    } else {
      setLoading(false);
      alert(result.error);
    }
  };

  const handleAddCidade = async () => {
    const newCidade = { nome, email, role };
    const result = await api.addCidade(newCidade);
    if (result.error === "") {
      getEntregas();
      toggleModal();

      setNome("");
      setEmail("");
      setRole("");

      setAlert({
        isOpen: true,
        title: "Sucesso",
        message: "Cidade adicionada com sucesso!",
      });
    } else {
      setAlert({ isOpen: true, title: "Erro", message: result.error });
    }
  };

  const toggleAlert = () => setAlert({ ...alert, isOpen: !alert.isOpen });

  useEffect(() => {
    getEntregas("aguardando");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const entregasAguardando = list.filter(
  //   (entrega) => entrega.status === "AGUARDANDO"
  // );
  // const entregasAceitas = list.filter((entrega) => entrega.status === "ACEITA");
  // const entregasEntregues = list.filter(
  //   (entrega) => entrega.status === "ENTREGUE"
  // );

  return (
    <div>
      <Row>
        {/* --------------------------------------------------------------------------------*/}
        {/* table-1*/}
        {/* --------------------------------------------------------------------------------*/}
        <Col lg="12 text-end mb-3">
          <Button className="btn" color="primary" onClick={toggleModal}>
            Adicionar novo
          </Button>
        </Col>
        <Col lg="12">
          <Card>
            <CardBody>
              <CardTitle tag="h5">Entregas</CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                Entregas cadastradas no sistema
              </CardSubtitle>

              <Nav fill justified pills tabs className="mt-5">
                <NavItem>
                  <NavLink
                    className={{ active: activeTab === "1" }}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    AGUARDANDO
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={{ active: activeTab === "2" }}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    ACEITAS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={{ active: activeTab === "3" }}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    ENTREGUES
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent style={{ padding: "30px 0px" }} activeTab={activeTab}>
                <TabPane tabId="1">
                  <EntregaTable entregas={list} />
                </TabPane>
                <TabPane tabId="2">
                  <EntregaTable entregas={list} />
                </TabPane>
                <TabPane tabId="3">
                  <EntregaTable entregas={list} />
                </TabPane>
              </TabContent>
              {/* <Table
                className="no-wrap mt-3 align-middle"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Email</th>

                    <th>Tipo</th>
                    <th style={{ width: 170 }}>#</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((tdata, index) => (
                    <tr key={index} className="border-top">
                      <td>
                        {tdata.nome}
                      </td>
                      <td>{tdata.email}</td>
                      <td>
                        {tdata.role}
                      </td>
                      <td>
                        <div className="button-group">
                          <Button className="btn" color="primary">
                            Editar
                          </Button>
                          <Button className="btn" color="warning">
                            Teste
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table> */}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Adicionar Nova Cidade</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="nome">Nome</Label>
              <Input
                type="text"
                id="nome"
                name="nome"
                placeholder="Nome da cidade"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="role">Tipo</Label>
              <Input
                type="select"
                id="role"
                name="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="">Selecione um tipo</option>
                <option value="admin">Admin</option>
                <option value="lojista">Lojista</option>
                <option value="motoboy">Motoboy</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddCidade}>
            Salvar
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <CustomAlert
        isOpen={alert.isOpen}
        toggle={toggleAlert}
        title={alert.title}
        message={alert.message}
      />
    </div>
  );
};

export default Entregas;
