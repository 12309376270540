import React from "react";
import { Table, Button } from "reactstrap";

const EntregaTable = ({ entregas }) => {
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Table className="no-wrap mt-3 align-middle" responsive borderless>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Bairro</th>
          <th>Status</th>
          <th>Forma pag.</th>
          <th style={{ width: 120 }}>#</th>
        </tr>
      </thead>
      <tbody>
        {entregas.map((tdata, index) => (
          <tr key={index} className="border-top">
            <td>
              {tdata.nome_cliente}
              <div className="d-flex align-items-center p-0">
                <div className="ms-0">
                  <span className="text-primary">
                    Entrega{" "} 
                    <p>
                    <small className="text-muted tz-">
                      {formatDate(tdata.created)}
                      {/* {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(tdata.created)} */}
                    </small>
                    </p>
                  </span>
                </div>
              </div>
            </td>
            <td>{tdata.bairro_setor}</td>
            <td>
              {tdata.status}
              {tdata.status == "aceita" ? " - " + tdata.status_motoboy : ""}
            </td>
            <td>{tdata.metodo}</td>
            <td>
              <div className="button-group">
                <Button className="btn" color="warning">
                  Detalhes
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default EntregaTable;
