import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import useApi from '../services/api';

import { useAuth } from "../components/AuthContext";

export default () => {
  //   const api = useApi();
  const navigate = useNavigate();

  const { logout } = useAuth();

  useEffect(() => {
    logout();

    
    //     navigate.reset({
    //         index: 0,
    //         routes: [{ name: 'Login' }]
    //    })

    // const doLogout = async() => {
    //   await api.logout();
    //  history.push('/login');
    // }
    // doLogout();
  }, []);
  navigate("/login");
  return null;
};
